/** @jsxRuntime classic */
/** @jsx jsx */
import { Link, jsx } from 'theme-ui'
import { styles } from './styles'

const Footer = (props) => (
  <footer sx={{ ...styles.footer, variant: 'footer' }}>
    {props.links && (
      <ul sx={styles.footerLinks}>
        {props.links.map((link, id) => (
          <li key={`footer-link-${id}`}>
            <Link
              sx={styles.footerLink}
              rel="noreferrer"
              target="_blank"
              href={link.url}
              key={`footer-link-${id}`}
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    )}

    <div sx={styles.footerTextContainer}>
      {props.subtitle && <span>{props.subtitle}</span>}
    </div>
  </footer>
)

export default Footer
