import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Label as LabelUI, jsx } from 'theme-ui'

const Label = React.forwardRef(
  ({ labelStyle, children, styles, ...props }, ref) => {
    return (
      <LabelUI
        ref={ref}
        sx={{ variant: `label.${labelStyle}`, ...styles }}
        {...props}
      >
        {children}
      </LabelUI>
    )
  },
)

export default Label
