/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from 'theme-ui'
import DOMPurify from 'isomorphic-dompurify'

const TextBlockRenderer = ({ textBlock, ...props }) => {
  // Add a hook to make all links open a new window
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank and rel attribute for security reasons
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer nofollow')
    }
  })
  const cleanHTML = DOMPurify.sanitize(textBlock, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    USE_PROFILES: {
      html: true,
    },
  })

  return (
    <div
      sx={{
        variant: 'styles.textEditor',
      }}
      dangerouslySetInnerHTML={{
        __html: cleanHTML,
      }}
      {...props}
    />
  )
}

export default TextBlockRenderer
