import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import styles from './styles'
import setTimezone from '../../utils/setTimezone'
import useCountdown from '../../hooks/useCountdown'
import timerByLocale from './TimerByLocale'

const CountDownBlock = React.forwardRef(({ countdown, locale }) => {
  const {
    content: { startDate, timezone },
  } = countdown

  const startDateTZ = setTimezone({
    date: startDate ? new Date(startDate) : new Date(),
    timezone,
  })

  const timeHook = useCountdown(startDateTZ)
  const timeText = timerByLocale[locale] || timerByLocale['en']

  const accumulateTime = timeHook.reduce(
    (accumulator, element, index) => [
      ...accumulator,
      { time: element, text: timeText[index] },
    ],
    [],
  )

  return startDate ? (
    <Box
      sx={{
        ...styles.countdownContainer,
        variant: `box.countDownBlock.${countdown.name}`,
      }}
    >
      {accumulateTime.map((element, index) => (
        <Box sx={styles.timeContainer} key={`${index}${element.text}`}>
          <span sx={styles.time}>{element.time}</span>
          <span sx={styles.timeText}>{element.text}</span>
        </Box>
      ))}
    </Box>
  ) : (
    ''
  )
})

export default CountDownBlock
