/** @jsx jsx */
/** @jsxRuntime classic */
import { useState } from 'react'
import { jsx, Link } from 'theme-ui'
import Box from '../Commons/Box'
import Image from '../Commons/Image'
import { TICK } from '../../constants/icons'

const addQueryParam = (url, name, value) =>
  value && url.searchParams.append(name, value)

const buildFacebookLink = ({ message, shareUrl, hashtag } = {}) => {
  const url = new URL('https://www.facebook.com/sharer/sharer.php')
  addQueryParam(url, 'u', shareUrl)
  addQueryParam(url, 'quote', message)
  addQueryParam(url, 'hashtag', hashtag && hashtag[0] && `#${hashtag[0]}`)
  return url
}

const buildWhatsappLink = ({ message } = {}) => {
  const url = new URL('https://api.whatsapp.com/send/?phone')
  addQueryParam(url, 'text', message)
  return url
}

const buildTwitterLink = ({ message, shareUrl, hashtag } = {}) => {
  const url = new URL('https://twitter.com/intent/tweet')
  addQueryParam(url, 'url', shareUrl)
  addQueryParam(url, 'text', message)
  addQueryParam(url, 'hashtags', hashtag && hashtag.join(','))
  return url
}

const buildCustomLink = ({ message } = {}) => message

const getSocialLink = (socialLink) => {
  const linkSwitch = {
    facebook: buildFacebookLink,
    whatsapp: buildWhatsappLink,
    twitter: buildTwitterLink,
    link: buildCustomLink,
  }
  return linkSwitch[socialLink?.name](socialLink) || ''
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    mx: '10px',
    width: '80px',
    position: 'relative',
  },
  icon: {
    width: '80px',
  },
  greenTickContainer: {
    position: 'absolute',
    width: '100%',
    height: '95%',
    bg: '#31B112',
    borderRadius: '15px',
    opacity: '0',
    transition: 'visibility 0.5s, opacity 0.5s',
  },
  greenTick: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30px',
    py: '5px',
  },
  copyBox: {
    color: '#31B112',
    border: '1px solid #31B112',
    borderRadius: '10px',
    p: '10px 15px',
    mt: '10px',
    visibility: 'hidden',
    transition: 'visibility 0.5s, opacity 0.5s',
  },
  fadeIn: {
    opacity: '1',
    visibility: 'visible',
  },
  fadeOut: {
    opacity: '0',
    visibility: 'hidden',
  },
}

const SocialBlock = ({ social, ...props }) => {
  const { content } = social || {}
  const [copy, setCopy] = useState(false)
  const [animation, setAnimation] = useState()
  const [reverseAnimation, setReverseAnimation] = useState()

  const onLinkClick = (social) => {
    navigator?.clipboard?.writeText(social?.shareUrl)
    setCopy(social)
    setAnimation(styles.fadeIn)
    setReverseAnimation(styles.fadeOut)
    setTimeout(() => {
      setAnimation(styles.fadeOut)
      setReverseAnimation(styles.fadeIn)
    }, 1300)
  }

  return (
    <Box sx={{ variant: 'box.socialBlock.' + social?.name }}>
      <Box {...props} sx={styles.container}>
        {content
          .filter((socialLink) => socialLink.isEnabled)
          .map((socialLink, i) => {
            return socialLink?.name !== 'link' ? (
              <Link
                key={i}
                sx={styles.link}
                href={getSocialLink(socialLink)}
                target="_blank"
              >
                <Image sx={styles.icon} src={socialLink?.shareImg} />
              </Link>
            ) : socialLink?.isAnimation ? (
              <Box sx={styles.link} onClick={() => onLinkClick(socialLink)}>
                <Box sx={{ ...styles.greenTickContainer, ...animation }}>
                  <Image sx={styles.greenTick} src={TICK} />
                </Box>
                <Image
                  sx={{ ...styles.icon, ...reverseAnimation }}
                  src={socialLink?.shareImg}
                />
              </Box>
            ) : (
              <Box sx={styles.link} onClick={() => onLinkClick(socialLink)}>
                <Image sx={{ ...styles.icon }} src={socialLink?.shareImg} />
              </Box>
            )
          })}
      </Box>
      <Box sx={{ ...styles.copyBox, ...animation, variant: 'box.copyBox' }}>
        {copy?.copyText}
      </Box>
    </Box>
  )
}

export default SocialBlock
