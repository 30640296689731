import React from 'react'
import ReactDOM from 'react-dom'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalWrapper: {
    display: 'block',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['97%', '80%'],
    maxWidth: '100%',
    height: ['99%', '95%'],
    backgroundColor: '#fff',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
  modalCloseButton: {
    fontSize: '1.4rem',
    cursor: 'pointer',
    backgroundColor: '#fff',
    border: 'none',
    mx: '10px',
    my: '5px',
    zIndex: '1',
  },
  modalText: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}

const Modal = React.forwardRef(({ onClose, children }, ref) =>
  children !== null && children !== undefined
    ? ReactDOM.createPortal(
        <React.Fragment ref={ref}>
          <div sx={styles.modalOverlay} />
          <div sx={styles.modalWrapper}>
            <div sx={styles.modal}>
              <div sx={styles.modalHeader}>
                <button sx={styles.modalCloseButton} onClick={onClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div sx={styles.modalText}>{children}</div>
            </div>
          </div>
        </React.Fragment>,
        document.body,
      )
    : null,
)

export default Modal
