import React from 'react'
import Image from '../Commons/Image'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Link, jsx } from 'theme-ui'

const ImageBlock = React.forwardRef(({ image, display }) => {
  const {
    content: { imageUrl, hyperlink, desktop = null },
  } = image
  const sourceList = desktop
    ? [
        {
          media: `(min-width:${desktop?.breakpointWidth}px)`,
          srcSet: desktop?.imageUrl,
        },
      ]
    : undefined

  const imageTag = (
    <Image
      src={imageUrl}
      sourceList={sourceList}
      sx={{ variant: 'box.imageBlock.' + image?.name, display: { display } }}
    />
  )

  return hyperlink ? (
    <Link href={hyperlink} target="_blank">
      {imageTag}
    </Link>
  ) : (
    imageTag
  )
})

export default ImageBlock
