import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button, Link } from 'theme-ui'

const styles = {
  button: {
    cursor: 'pointer',
  },
}

const ButtonBlock = React.forwardRef(({ button }) => {
  const {
    content: { text = '', link = '', variant = '', target = '_self' },
  } = button

  return (
    text && (
      <Link
        href={link}
        target={target}
        {...(target === '_blank' && { rel: 'noopener noreferrer' })}
        aria-label={`external-link to: ${link}`}
      >
        <Button
          type="button"
          sx={{ ...styles.button, variant: `buttons.${variant}` }}
        >
          {text}
        </Button>
      </Link>
    )
  )
})

export default ButtonBlock
