export const styles = {
  footer: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    padding: ['0px 30px 25px 30px', '0px 30px'],
    width: '100vw',
    justifyContent: ['center', 'space-between'],
    alignItems: 'center',
    height: ['100%', '100px'],
    backgroundColor: '#000',
    '*': {
      color: '#fff',
      fontFamily: 'light',
      fontSize: '16px',
    },
  },
  footerLinks: {
    width: ['100%', '50%'],
    listStyle: 'none',
    mt: ['25px', '33px'],
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: ['center', ''],
    padding: '0px',
    li: {
      ':not(:nth-of-type(1))': {
        marginLeft: ['0rem', '0.7rem'],
      },
      marginRight: ['0rem', '0.7rem'],
    },
  },
  footerLink: {
    textDecoration: 'none',
    '&:hover': {
      color: 'light',
    },
  },
  footerTextContainer: {
    width: ['100%', '50%'],
    paddingRight: ['0px', '60px'],
    textAlign: ['center', 'right'],
    display: ['inline', 'flex'],
    flexDirection: ['column'],
    mt: '28px',
    mx: ['27px', 'auto'],
  },
}
