/* eslint-disable react/no-children-prop */
/** @jsxRuntime classic */
/** @jsx jsx */
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { jsx } from 'theme-ui'
import Box from '../Box'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  markdown: {
    maxWidth: ['360px', '1000px'],
    wordWrap: 'break-word ',
    color: '#000',
    lineHeight: '1.4rem',
    whiteSpace: 'pre-line',
    mt: ['10px', '40px'],
    mx: ['15px', '0px'],
    li: {
      p: {
        position: 'relative',
        top: '0px',
        lineHeight: '1.4rem',
      },
    },
    table: {
      borderLeft: '1px solid black',
      borderSpacing: '0px',
      th: {
        background: 'black',
        color: 'white',
        border: '1px solid black',
      },
      td: {
        borderRight: '0.5px solid black',
        borderBottom: '0.5px solid black',
      },
    },
  },
}

const MarkdownContent = ({ content }) => {
  return (
    <Box sx={styles.container}>
      <ReactMarkdown
        plugins={[remarkGfm]}
        sx={styles.markdown}
        children={content}
        renderers={{
          link: (props) => {
            if (props.href.startsWith('http')) {
              return (
                <a
                  href={props.href}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  {props.children}
                </a>
              )
            }
            return <a href={props.href}>{props.children}</a>
          },
        }}
      />
    </Box>
  )
}

export default MarkdownContent
