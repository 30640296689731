import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Image as ImageUI, jsx } from 'theme-ui'
const Image = React.forwardRef(
  ({ src, imageStyle, styles, sourceList, ...props }, ref) => {
    return (
      <picture>
        {sourceList &&
          sourceList.map((sourceProps, i) => (
            <source key={i} {...sourceProps} />
          ))}
        <source srcSet={src} />
        <ImageUI
          sx={{ variant: `images.${imageStyle}`, ...styles }}
          src={src}
          ref={ref}
          {...props}
        />
      </picture>
    )
  },
)
export default Image
