import Box from '../../components/Commons/Box'
import Text from '../../components/Commons/Text'

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

const Header = () => (
  <Box>
    <Text>Header</Text>
  </Box>
)

export default Header
