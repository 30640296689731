/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Select } from 'theme-ui'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import styles from './styles'

const LanguageSwitch = ({ pageSettings }) => {
  const router = useRouter()
  const queryLocale = router?.query?.locale
  const campaign = router?.query?.campaign
  const page = router?.query?.page

  const locales = pageSettings?.localeToggle?.locales
  const [localeSelected, setLocaleSelected] = useState([])
  const [localesToShow, setLocalesToShow] = useState([])

  useEffect(() => {
    const defaultLocale = locales.find((locale) => locale.value === queryLocale)
    getListOfLocales(defaultLocale)
    setLocaleSelected(defaultLocale)
    return () => setLocalesToShow([])
  }, [queryLocale])

  const getListOfLocales = (defaultLocale) => {
    setLocalesToShow((previousLocales) => [...previousLocales, defaultLocale])
    locales
      .filter((locale) => locale.value !== defaultLocale.value)
      .forEach((locale) =>
        setLocalesToShow((previousLocales) => [...previousLocales, locale]),
      )
  }

  const pushToLocale = (localeLabel) => {
    const localeSelected = locales.find(
      (locale) => locale.value === localeLabel,
    )
    return (window.location.href = `/${campaign}/${localeSelected?.value}/${page}`)
  }
  return (
    <Box sx={{ ...styles.container, variant: 'styles.localeToggle' }}>
      <Select
        sx={styles.selectContainer}
        onChange={(e) => pushToLocale(e.target.value)}
        value={localeSelected.value}
      >
        {localesToShow?.map((locale, index) => (
          <option key={`${index}${locale}`} value={locale.value}>
            {locale?.textByLocale?.[queryLocale] || locale.label}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default LanguageSwitch
