/** @jsxRuntime classic */
/** @jsx jsx */
import { Box as BoxUI, jsx } from 'theme-ui'

import React from 'react'

const Box = React.forwardRef(
  ({ boxStyle, dataCy, disabled = false, styles, ...props }, ref) => {
    return (
      <BoxUI
        data-cy={dataCy}
        sx={{ variant: `box.${boxStyle}`, ...styles }}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    )
  },
)
export default Box
