/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import ReactPlayer from 'react-player/lazy'

const styles = {
  video: {
    width: '100%',
    height: '100%',
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxHeight: '500px',
    height: ['auto', '500px'],
    maxWidth: '800px',
    margin: '0 auto ',
  },
}

const Video = ({ video, display }) => {
  return (
    <div sx={(styles.videoContainer, { display: { display } })}>
      <ReactPlayer
        sx={styles.video}
        url={video?.content?.videoUrl}
        width="400"
        playsinline
        playing
        controls={true}
        config={{
          file: {
            attributes: { controlsList: 'nodownload' },
            forceAudio: true,
            forceVideo: true,
          },
        }}
        muted={false}
      />
    </div>
  )
}

export default Video
