import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import PollCard from '../PollCard'

const styles = {
  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}

const Poll = React.forwardRef(({ poll, display }) => {
  const options = poll?.content?.options

  return (
    <div sx={(styles.itemsContainer, { display: { display } })}>
      {options.map((option, i) => (
        <PollCard key={i} option={option} />
      ))}
    </div>
  )
})

export default Poll
