import { useState, useEffect } from 'react'

export default function useCountdown(finishDate) {
  const [tick, setTick] = useState(0)

  useEffect(() => setTimeout(() => setTick(tick + 1), 1000))

  const getDifference = (finishDate) => {
    const currentDate = new Date()
    return finishDate - currentDate
  }

  const differenceInMilliseconds = getDifference(finishDate)

  const minTimer = 0
  if (differenceInMilliseconds <= minTimer) return Array(4).fill(minTimer)

  const days = Math.floor(differenceInMilliseconds / 1000 / 60 / 60 / 24)

  const hoursDiff = differenceInMilliseconds - days * 1000 * 60 * 60 * 24
  const hours = Math.floor(hoursDiff / 1000 / 60 / 60)

  const minsDiff = hoursDiff - hours * 1000 * 60 * 60
  const mins = Math.floor(minsDiff / 1000 / 60)

  const secondsDiff = minsDiff - mins * 1000 * 60
  const seconds = Math.floor(secondsDiff / 1000)

  return [days, hours, mins, seconds]
}
