export default {
  countdownContainer: {
    background: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    color: 'black',
    padding: '10px',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  time: {
    fontSize: '5rem',
  },
  timeText: {
    fontSize: '1rem',
  },
}
