/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { useRouter } from 'next/router'
import ReactPlayer from 'react-player/lazy'
import Input from '../Commons/Input'
import Image from '../Commons/Image'
import FormData from 'form-data'
import Box from '../Commons/Box'
import Text from '../Commons/Text'
import Label from '../Commons/Label'

const ERR_FILE_NOT_SET = 'File not selected'

const mediaFormData = (file, fileType) => {
  if (file) {
    const formData = new FormData()
    formData.append('fileToUpload', file, file.name)
    return { fileType, file: formData }
  }
}

const UploadFileBlock = ({ uploadFileBlock, setFile, formError, ...props }) => {
  const router = useRouter()
  const locale = router?.query?.locale || 'en'

  const { file, settings } = uploadFileBlock
  const {
    fileType,
    maxSize,
    maxSizeErrorMessage,
    maxDuration,
    maxDurationErrorMessage,
    required = true,
    label,
    requiredErrorMessage,
  } = settings

  const [errorMessage, setErrorMessage] = useState('')
  const requiredError = formError?.message === ERR_FILE_NOT_SET

  if (
    requiredError &&
    requiredErrorMessage?.[locale] &&
    errorMessage !== requiredErrorMessage?.[locale]
  ) {
    setErrorMessage(requiredErrorMessage?.[locale])
  }
  const [fileSource, setFileSource] = useState(file)

  function fileReader(file, as) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader[as](file)
    })
  }

  function loadVideo(file) {
    return new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          resolve(this)
        }

        video.onerror = function () {
          reject('Invalid video. Please select a video file.')
        }
        video.src = window.URL.createObjectURL(file)
      } catch (e) {
        reject(e)
      }
    })
  }

  const previewAndUploadFile = async (e) => {
    setErrorMessage('')
    const file = e.target.files[0]
    const fileURL = await fileReader(file, 'readAsDataURL')
    if (fileType === 'video') {
      const video = await loadVideo(file)
      if (maxDuration && video.duration > maxDuration) {
        setErrorMessage(maxDurationErrorMessage?.[locale])
        return
      }
    }
    if (file.size > maxSize) {
      setErrorMessage(maxSizeErrorMessage?.[locale])
      return
    }
    setFileSource(fileURL)
    setFile(mediaFormData(file, fileType))
  }

  const allowedExtensionsByFileType = {
    video: '.mp4,.mov',
    image: '.jpg,.jpeg,.png',
    'application/pdf': '.pdf',
  }
  return (
    // Workaround to get from document and check if required
    <Box
      className={`file-upload-block ${
        required && 'file-upload-block-required'
      }`}
      sx={{ variant: 'forms.fileInputContainer' }}
    >
      {label?.[locale] && (
        <Label sx={{ variant: 'forms.label' }}>{label[locale]}</Label>
      )}
      <Input
        id="upload_file"
        type="file"
        accept={allowedExtensionsByFileType[fileType]}
        onChange={(e) => previewAndUploadFile(e)}
        {...props}
        sx={{ variant: 'forms.fileInput' }}
      />
      {fileSource && fileType === 'video' && (
        <ReactPlayer
          url={fileSource}
          height={250}
          width={300}
          autoPlay
          onContextMenu={(e) => e.preventDefault()}
          muted={true}
          playing={true}
          controls={true}
          playsinline
        />
      )}
      {fileSource && fileType === 'image' && (
        <Image src={fileSource} height={250} width={300} />
      )}
      {fileSource && fileType === 'application/pdf' && (
        <object
          sx={{ minHeight: '500px' }}
          data={fileSource}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
      {errorMessage && errorMessage !== '' && (
        <Text
          as="p"
          sx={{
            variant: `forms.errorMessage`,
          }}
        >
          {errorMessage}
        </Text>
      )}
    </Box>
  )
}

export default UploadFileBlock
