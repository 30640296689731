/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FormBuilder } from '@guidesmiths/react-form-builder'
import { useRouter } from 'next/router'

import { ReaderAPI } from '../../repository'
import Box from '../Commons/Box'
import TextBlockRenderer from '../Commons/TextBlockRenderer'
import Header from '../Header'
import Footer from '../Footer'
import Poll from '../Poll'
import Text from '../Commons/Text'
import Video from '../Commons/Video'
import Modal from '../Commons/Modal'
import SocialBlock from '../SocialBlock'
import ImageBlock from '../ImageBlock'
import UploadFileBlock from '../UploadFileBlock'
import ButtonBlock from '../ButtonBlock'
import MarkdownContent from '../Commons/ModalMarkdownContent'
import { useModalDispatch, useModalState } from '../../contexts/modal'
import IframeContainer from '../Commons/IframeContainer'
import Iframe from '../Commons/Iframe'
import countryAndRegionsData from '../../constants/countries.json'
import CountDownBlock from '../CountDownBlock'
import LanguageSwitch from '../LanguageSwitch'
import utils from '../../utils'

const defaultFormConfig = {
  callForAction: [
    {
      caption: 'Enter',
      type: 'submit',
    },
  ],
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}

const DefaultTemplate = ({
  page,
  campaign,
  sendForm,
  setUploadFile,
  formError,
}) => {
  const modalState = useModalState()
  const modalDispatch = useModalDispatch()
  const router = useRouter()
  const locale = router?.query?.locale

  const localesEnabled = page?.settings?.localeToggle?.enabled

  const onLinkOpen = (content) => {
    if ('parentIFrame' in window) {
      // eslint-disable-next-line no-undef
      window.parentIFrame.scrollTo(0, 0)
    }
    modalDispatch({
      type: 'show',
      payload: <MarkdownContent content={content} />,
    })
  }

  const onIframeOpen = (html) => {
    modalDispatch({
      type: 'show',
      payload: <Iframe src={'data:text/html;charset=utf-8,' + html} />,
    })
  }

  const getContentToModal = (layout) => {
    let contents = []

    layout.forEach((element) => {
      contents.push(element.content.text)
    })

    return contents.join('')
  }

  const layoutTypes = {
    form: (layoutElement, i) => {
      return (
        <FormBuilder
          key={i}
          countryAndRegionsData={countryAndRegionsData}
          language={locale}
          {...{
            idForm: defaultFormConfig.id,
            form: layoutElement.content,
            isoCode:
              locale === 'en' || locale === 'en_IE' || locale === 'ar_SA'
                ? utils.isoCodeAndLocale[locale]
                : locale,
            onSubmit: sendForm,
            onLinkOpen: async (name) => {
              if (name === 'terms' || name === 'privacy') {
                const newWindow = window.open()
                const { data } = await ReaderAPI.getPageBySlug({
                  domain: 'UI',
                  campaignId: page.campaignId,
                  pageSlug: name,
                  locale,
                })

                const content = getContentToModal(data?.layout)
                const path = router.asPath.replace(/\/[^/]+$/, `/${data?.slug}`)

                data?.settings?.format === 'popUp'
                  ? onIframeOpen(content)
                  : (newWindow.location = path) || window.location.replace(path)
              } else {
                onLinkOpen(
                  layoutElement?.content?.modal[name].replace(/\\n/g, '\n'),
                )
              }
            },
          }}
        />
      )
    },
    text: (layoutElement, i) => (
      <TextBlockRenderer
        sx={{ variant: 'box.textBlock.' + layoutElement?.name }}
        key={i}
        textBlock={layoutElement?.content?.text}
      />
    ),

    image: (layoutElement, i) => <ImageBlock key={i} image={layoutElement} />,
    button: (layoutElement, i) => (
      <ButtonBlock key={i} button={layoutElement} />
    ),
    poll: (layoutElement, i) => <Poll key={i} poll={layoutElement} />,
    video: (layoutElement, i) => <Video key={i} video={layoutElement} />,
    uploadFile: (layoutElement, i) => (
      <UploadFileBlock
        key={i}
        uploadFileBlock={layoutElement}
        setFile={setUploadFile}
        formError={formError}
      />
    ),
    social: (layoutElement, i) => (
      <SocialBlock key={i} social={layoutElement} />
    ),
    countdown: (layoutElement, i) => (
      <CountDownBlock key={i} countdown={layoutElement} locale={locale} />
    ),
  }
  const localesOnToggle = page?.settings?.localeToggle?.locales?.map(
    (l) => l.value,
  )
  return (
    <div sx={{ margin: '0 auto' }}>
      {page?.header && <Header content={page?.header} />}
      <Box sx={styles.container}>
        <main sx={styles.container}>
          {campaign?.localeToggle &&
            localesEnabled &&
            localesOnToggle.includes(locale) && (
              <LanguageSwitch pageSettings={page?.settings} />
            )}
          {page?.layout ? (
            page?.layout.map((layoutElement, i) => {
              return layoutTypes[layoutElement.type](layoutElement, i)
            })
          ) : (
            <Text textStyle="title">No info available</Text>
          )}
        </main>
        {formError && formError.message !== 'File not selected' && (
          <p>Something went wrong submitting the form :( </p>
        )}
      </Box>
      <Modal
        show={modalState !== null}
        onClose={() => modalDispatch({ type: 'hide' })}
      >
        {modalState}
      </Modal>
      {page?.settings?.isFooter && campaign?.footer && (
        <Footer {...campaign.footer[locale]} />
      )}
      <IframeContainer />
    </div>
  )
}

export default DefaultTemplate
