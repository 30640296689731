/** @jsxRuntime classic */
/** @jsx jsx */
import { Button as ButtonUI, Spinner, jsx } from 'theme-ui'

import React from 'react'

const defaultStyles = {
  border: 'transparent',
  color: 'primaryText',
  fontSize: 2,
  fontWeight: 'buttons',
  lineHeight: 1,
  cursor: 'pointer',
  px: '32px',
  py: '16px',
  /*   width: '100%', */
}
const Button = React.forwardRef(
  ({ disabled, buttonStyle, caption, isLoading, styles, ...props }, ref) => (
    <ButtonUI
      ref={ref}
      {...props}
      sx={{
        ...defaultStyles,
        ...(disabled && { background: 'gray' }),
        variant: `buttons.${buttonStyle}`,
        ...styles,
      }}
    >
      {caption}
      {isLoading && <Spinner data-cy="button-loading" />}
    </ButtonUI>
  ),
)
export default Button
