export default {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    position: 'absolute',
    zIndex: 99,
  },
  selectContainer: {
    width: 'auto',
    cursor: 'pointer',
    paddingRight: '30px',
  },
}
