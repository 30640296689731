import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Box from '../Commons/Box'
import Image from '../Commons/Image'
import Text from '../Commons/Text'
import Button from '../Commons/Button'

const PollCard = React.forwardRef(({ option }) => {
  const onVote = () => {
    console.log(option.id)
  }

  return (
    <Box
      sx={{
        mx: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Image src={option?.image} />
      <Text textStyle={'bigTitle'}>{option.title}</Text>
      <Text textStyle={'bold'}>{option.subtitle}</Text>
      <Button caption={'Vote'} onClick={onVote} />
    </Box>
  )
})

export default PollCard
