/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

import React from 'react'

const defaultStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  padding: '10px',
}

const Iframe = React.forwardRef(
  ({ iframeStyle, dataCy, src, styles, ...props }, ref) => {
    return (
      <iframe
        data-cy={dataCy}
        sx={{ ...defaultStyles, variant: `iframe.${iframeStyle}`, ...styles }}
        ref={ref}
        src={src}
        {...props}
      />
    )
  },
)
export default Iframe
